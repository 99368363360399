/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment, useState } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import SEO from "../components/Seo"
import Search from "../components/Verb/Search"
import ToggleButton from "../components/ToggleButton"
import {
  AllVerbsIcon,
  TrendingIcon,
  PopularVerbsIcon,
  PlaneIcon,
  SchoolIcon,
} from "../assets/icons"
import { verbSlug } from "../utils/helper"
import { useVerbs } from "../components/Verb/query"
import topVerbs from "../config/top-verbs"
import language from "../config/language"

const toggleOptions = [
  { icon: <TrendingIcon />, label: "View Curated", value: true },
  { icon: <AllVerbsIcon />, label: "View All", value: false },
]

// TODO refactor this craziness of a page and styles / make curated lists into a map

const VerbsArchive = () => {
  const [curated, setCurated] = useState(true)
  const verbs = useVerbs().allMongodbVerbsFrench.nodes.sort((a, b) =>
    a.verb.localeCompare(b.verb)
  )

  // Create dictionary of letters

  let dict = verbs.reduce((a, c) => {
    let k = c.verb[0]
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLocaleUpperCase()

    if (a[k]) a[k].push(c.verb)
    else a[k] = [c.verb]
    return a
  }, {})

  const renderDictionary = () => {
    return Object.keys(dict).map(key => (
      <li key={key}>
        <h4>{key}</h4>
        <ul>
          {dict[key].map((item, index) => (
            <VerbListItem key={index}>
              <Link to={verbSlug(item)}>{item}</Link>
            </VerbListItem>
          ))}
        </ul>
      </li>
    ))
  }

  return (
    <Fragment>
      <SEO title="Verbs" />
      <PageTitle sx={{ fontSize: [32, 42] }}>Verb Conjugations</PageTitle>
      <TopContainer sx={{ mb: [30, 60] }}>
        <Search flex />
        <ToggleButton
          state={curated}
          setState={setCurated}
          options={toggleOptions}
        />
      </TopContainer>
      <div>
        {curated ? (
          <Grid
            sx={{
              svg: { fill: "primary" },
              h4: { fontSize: [18, 22] },
              gridTemplateColumns: ["1fr", "repeat(2, 1fr)"],
            }}
          >
            <div>
              <CuratedList>
                <div className="heading">
                  <PopularVerbsIcon />
                  <h4>Most Searched Verbs</h4>
                </div>
                <ul>
                  {topVerbs().trending.map(v => (
                    <VerbListItem key={v}>
                      <Link to={verbSlug(v)}>{v}</Link>
                    </VerbListItem>
                  ))}
                </ul>
              </CuratedList>
              <CuratedList>
                <div className="heading">
                  <SchoolIcon />
                  <h4>AP Exam Essentials</h4>
                </div>
                <ul>
                  {topVerbs().exam.map(v => (
                    <VerbListItem key={v}>
                      <Link to={verbSlug(v)}>{v}</Link>
                    </VerbListItem>
                  ))}
                </ul>
              </CuratedList>
            </div>
            <div>
              <CuratedList>
                <div className="heading">
                  <PlaneIcon />
                  <h4>Helpful Travel Verbs</h4>
                </div>
                <ul>
                  {topVerbs().essential.map(v => (
                    <VerbListItem key={v}>
                      <Link to={verbSlug(v)}>{v}</Link>
                    </VerbListItem>
                  ))}
                </ul>
              </CuratedList>
            </div>
          </Grid>
        ) : (
          <AllList sx={{ columns: [2, 3] }}>{renderDictionary()}</AllList>
        )}
      </div>
    </Fragment>
  )
}

const TopContainer = styled.div`
  position: relative;
  opacity: 0;
  animation: fadeInUp ease 1s forwards;
  z-index: 1;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .verb-search,
  .toggle-button {
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 50px;
`

const VerbListItem = styled.li`
  padding: 5px 10px;
  border: 1px solid transparent;
  transition: all ease 0.3s;

  a {
    color: ${language.color_primary};
    font-weight: 600;
    display: block;
    transition: all ease 0.3s;
  }

  &:hover {
    border-color: ${language.color_highlight[1]};
    background: ${language.color_highlight[0]};

    a {
      transform: translateX(4px);
    }
  }
`

const AllList = styled.ul`
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards 0.3s;
  column-gap: 40px;

  h4 {
    font-size: 42px;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 20px;
    padding-left: 15px;
  }
`

const CuratedList = styled.div`
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards 0.3s;
  margin-bottom: 50px;

  a {
    display: block;
    font-weight: 600;
    font-size: 17px;
  }

  .heading {
    border: 1px solid gainsboro;
    border-bottom: none;
    display: flex;
    align-items: center;
    padding: 13px 20px;
    background: #fbfbfb;

    svg {
      height: 32px;
      margin-right: 20px;
    }
  }

  ul {
    border: 1px solid gainsboro;
    padding: 20px;
    column-count: 2;
    column-gap: 20px;
  }
`

const PageTitle = styled.h1`
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeIn ease 0.5s forwards;
`

export default VerbsArchive
