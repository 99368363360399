export default () => {
  switch (process.env.GATSBY_LANGUAGE) {
    case "French":
      return {
        trending: [
          "être",
          "avoir",
          "aller",
          "faire",
          "pouvoir",
          "sortir",
          "commencer",
          "préparer",
          "écrire",
          "apprendre",
          "croire",
          "répéter",
        ],
        essential: [
          "être",
          "comprendre",
          "venir",
          "vouloir",
          "parler",
          "aller",
          "faire",
          "aimer",
          "adorer",
          "demander",
          "passer",
          "rester",
          "finir",
          "chercher",
          "aider",
          "choisir",
          "appeler",
          "arriver",
          "essayer",
          "voyager",
        ],
        exam: [
          "falloir",
          "exiger",
          "exprimer",
          "indiquer",
          "réfléchir",
          "introduire",
          "permettre",
          "vivre",
          "envelopper",
          "apprendre",
        ],
      }
    default:
      return
  }
}
